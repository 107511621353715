import {PageProps} from 'gatsby';
import React from 'react';
import DefaultLayout from '../layouts/default';
import HfSliderHero, {
  ViewModel as SliderHeroViewModel,
} from '../components/hf-slider-hero';
import heroImage from '../images/electronic-solution-hero.jpg';
import solution1Image from '../images/electronic-solution-1.jpg';
import solution2Image from '../images/electronic-solution-2.jpg';
import solution3Image from '../images/electronic-solution-3.jpg';
import SectionSolutions, {
  ViewModel as SectionSolutionsViewModel,
} from '../components/section-solutions';
import HfSeo from '../components/hf-seo';

const HERO_DATA: SliderHeroViewModel = [
  {
    title: '电子行业',
    imageUrl: heroImage,
  },
];
const SOLUTIONS_DATA: SectionSolutionsViewModel = [
  {
    requirement:
      '客户为手机电池生产商，在电池极耳上激光烧刻二维码，读取此二维码，以串口通讯将条码内容上传客户数据库',
    image: solution1Image,
  },
  {
    requirement:
      '客户为电子行业生产制造商，读取硅棒包装盒上的条形码，视野较大，一次读取300X300mm视野内的两个条码，普通读码器无法可靠。同时需要以太网协议将条码内容发送。',
    solution: '使用120万像素的BRV-660读码器，可以稳定读取',
    image: solution2Image,
  },
  {
    requirement:
      '客户为电子行业生产制造商，读取芯片上的二维码，以太网协议将条码内容上传客户数据库',
    solution:
      '使用120万像素的BRV-660读码器，即使芯片表面是黑色，也可以稳定读取',
    image: solution3Image,
  },
];

export default function ElectronicSolution({location}: PageProps) {
  return (
    <DefaultLayout location={location}>
      <HfSliderHero vm={HERO_DATA} />
      <SectionSolutions vm={SOLUTIONS_DATA} />
    </DefaultLayout>
  );
}

export const Head = () => <HfSeo title="电子半导体 | 唯视智能" />;
